<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit liability details</v-toolbar-title>

            <v-spacer />
            
            <!-- Removed :disabled="!isValid" - engineers want to partial save -->
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="isValid"
                >
                    <v-row>
                        <v-select
                            label="Did anyone witness the damage?"
                            v-model="liabilityWitnessIdLocal"
                            :items="lookups.allLiabilityTypes"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row v-if="liabilityWitnessed">
                        <v-textarea
                            label="If yes, please give details of their statement"
                            v-model="liabilityDetailsLocal"
                            hint="Please give any additional information relating to their statement, including their name and contact details"
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import instructionsAgent from "../instructionsAgent.js";
import optionSetAgent from "../../Ods/optionSetAgent.js";

export default {
    props: {
        id: String,
        liabilityWitnessId: Number,
        liabilityDetails: String,
    },
    
    data: function () {
        return {
            liabilityWitnessIdLocal: this.liabilityWitnessId,
            liabilityDetailsLocal: this.liabilityDetails,

            lookups: {
                allLiabilityTypes: [],
            },

            isValid: true,

            rules: {
                required: [v => !!v || "Required"],
            }
        };
    },
    
    computed: {
        liabilityWitnessed: function () {
            return this.liabilityWitnessIdLocal === 313670;    // Yes
        },
    },

    mounted: function () {
        var l = this.$coreUi.loading();

        optionSetAgent.getOptionSetValues("vm-liability-details-ynu").then(data => {
            this.lookups.allLiabilityTypes = data.optionSetValueProperties;

            if (this.lookups.allLiabilityTypes.every(x => x.id !== this.liabilityWitnessIdLocal))
                this.liabilityWitnessIdLocal = null;

        }).then(() => {

            l.dismiss();
            this.refreshValidation();

        }).catch(console.error);
    },

    methods: {

        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            instructionsAgent.getInstruction(this.id).then((result) => {

                // Set fields and save
                result.aspectData.formBuilder.formData["vm-liability-details-witness-lov"] = this.liabilityWitnessIdLocal;
                result.aspectData.formBuilder.formData["vm-liability-details-witness-details-text"] = this.liabilityWitnessed ? this.liabilityDetailsLocal : null;
                
                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.odsEntityPicker;
                delete result.aspectData.instructionWorkTypeDetails;
                delete result.aspectData.instructionB2BDetails;
                delete result.aspectData.locationCoordinate;
                
                instructionsAgent.saveInstruction(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>

<style>
.theme--light.v-sheet .tiptap-vuetify-editor > .v-card {
    margin: 0;
}
</style>